import classNames from "classnames";
import React from "react";
import { SanityLandingPageTitleBoxHero } from "../../model/landing-page";
import BlockContent from "../BlockContent/BlockContent";
import { Col, Row, Wrapper } from "../Grid";
import ImageBlock from "../ImageBlock/ImageBlock";
import LandingPageContentCard from "../LandingPageContentCard/LandingPageContentCard";
import SocialLinks from "../SocialLinks/SocialLinks";
import styles from "./LandingPageContent.module.scss";

const TitleBoxHero = ({
  className,
  title,
  value,
  social
}: {
  className: string;
  value: SanityLandingPageTitleBoxHero;
  title: string;
  social: boolean;
}) => (
  <Wrapper className={classNames(className, styles.noPaddingMobile)}>
    <Row justify="center" className={styles.titleRow}>
      <Col sm={8} md={6} lg={5} className={styles.titleCol}>
        <LandingPageContentCard className={styles.titleCard}>
          <BlockContent
            className={styles.titleText}
            content={value.content}
            bodyVariant="default"
          />
        </LandingPageContentCard>
      </Col>
      <Col xs={12} sm={12} md={10} className={styles.noPadding}>
        {social && <SocialLinks className={styles.social} title={title} />}
        {value.image && (
          <ImageBlock
            node={value.image}
            className={styles.heroImage}
            quality={95}
            width={1175}
          />
        )}
      </Col>
    </Row>
  </Wrapper>
);

export default TitleBoxHero;
