import React, { ReactNode } from "react";
import classnames from "classnames";
import styles from "./LandingPageContentCard.module.scss";
import Card from "../Card/Card";

const LandingPageContentCard = ({
  className,
  children,
  dir
}: {
  className?: string;
  children: ReactNode;
  dir?: "ltr" | "rtl";
}) => (
  <Card className={classnames(styles.root, className)} dir={dir}>
    {children}
  </Card>
);

export default LandingPageContentCard;
