import { WindowLocation } from "@reach/router";
import classNames from "classnames";
import { graphql, navigate } from "gatsby";
import React from "react";
import EventCard from "../components/EventCard/EventCard";
import EventModal from "../components/EventModal/EventModal";
import { Wrapper } from "../components/Grid";
import ContentCard from "../components/LandingPageContent/ContentCard";
import TitleBoxHero from "../components/LandingPageContent/TitleBoxHero";
import TeachRow from "../components/TeachRow/TeachRow";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityPage } from "../model/common";
import { SanityEvent, SanityEventsRow } from "../model/events";
import {
  SanityLandingPageTitleBoxHero,
  SanityMainContentCard
} from "../model/landing-page";
import { GlobalMenus } from "../model/menu";
import { urlForDocument } from "../urls";
import { SiteArea } from "../utils/analytics";
import {
  eventModalUrl,
  useEventOccurrences,
  useFocussedEventOccurrence
} from "../utils/events-util";
import styles from "./EventsPage.module.scss";

export const pageQuery = graphql`
  query EventsPage($_id: String!, $language: String, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityEventListingPage(_id: { eq: $_id }) {
      _id
      _type
      title
      language
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      hero: _rawHero(resolveReferences: { maxDepth: 5 })
      content: _rawContent(resolveReferences: { maxDepth: 7 })
    }
    listing: allSanityEvent(
      filter: { language: { eq: $language } }
      sort: { fields: [_createdAt], order: DESC }
    ) {
      nodes {
        ...SanityEvent
      }
    }
  }
`;

const EventsPage = ({
  pageContext,
  data: { menus, page, listing },
  location
}: EventsPageProps) => {
  const { title, hero, content } = page;
  const eventOccurrences = useEventOccurrences(listing.nodes);
  const focussedEvent = useFocussedEventOccurrence(eventOccurrences);

  return (
    <PageLayout
      siteArea={SiteArea.TEACH}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      {focussedEvent && (
        <EventModal
          isOpen={true}
          onClose={() => navigate(urlForDocument(page))}
          value={focussedEvent}
        />
      )}
      <Wrapper className={classNames(styles.content)}>
        <TitleBoxHero
          social
          className={styles.hero}
          title={page.title}
          value={hero}
        />
        {content.map(item => {
          switch (item._type) {
            case "landingPageContentCard":
              return (
                <ContentCard
                  key={item._key}
                  value={item as SanityMainContentCard}
                />
              );
            case "eventsRow": {
              const {
                title,
                subtitle,
                category,
                _key
              } = item as SanityEventsRow;
              const cards = eventOccurrences
                .filter(e => e.event.category === category)
                .map(e => (
                  <EventCard
                    key={e._key}
                    value={e}
                    url={eventModalUrl(page, e)}
                  />
                ));
              return cards.length ? (
                <TeachRow
                  key={_key}
                  title={title}
                  subtitle={subtitle}
                  cards={cards}
                />
              ) : null;
            }
            default:
              throw new Error();
          }
        })}
      </Wrapper>
    </PageLayout>
  );
};

export default EventsPage;

interface EventsPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityPage & {
      hero: SanityLandingPageTitleBoxHero;
      content: Array<SanityMainContentCard | SanityEventsRow>;
    };
    listing: { nodes: SanityEvent[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
